// Fonts are declared in brand-fonts-provider.ts
$py-fonts: (
  'primary': 'primary, primary-fallback-0, primary-fallback-1, sans-serif',
  'primary-light': 'primary-light, primary-light-fallback-0, primary-light-fallback-1, sans-serif',
  'secondary': 'secondary, secondary-fallback-0, secondary-fallback-1, sans-serif',
  'secondary-bold': 'secondary-bold, secondary-bold-fallback-0, secondary-bold-fallback-1, sans-serif',
);

:root {
  @each $font, $value in $py-fonts {
    --py-font-#{$font}: #{$value};

    .py-font-#{$font} {
      font-family: var(--py-font-#{$font});
    }
  }
}
